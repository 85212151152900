<script setup>
import {computed} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faBars,
    faCartShopping,
    faCheck,
    faChevronUp,
    faCircleExclamation,
    faCircleInfo,
    faClock,
    faEnvelope,
    faMapPin,
    faStar,
    faTag,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import {
    faApple,
    faDeezer,
    faFacebook,
    faInstagram,
    faLinkedin,
    faSoundcloud,
    faSpotify,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {faFileLines} from "@fortawesome/free-regular-svg-icons";

//icons should also be added to the validator as no local var should be used in it
const fa = {
    'info': {name: 'fa-solid fa-circle-info', file: faCircleInfo},
    'success': {name: 'fa-solid fa-check', file: faCheck},
    'error': {name: 'fa-solid fa-circle-exclamation', file: faCircleExclamation},
    'doc': {name: 'fa-regular fa-file-lines', file: faFileLines},
    'purchase': {name: 'fa-solid fa-cart-shopping', file: faCartShopping},
    'star': {name: 'fa-solid fa-star', file: faStar},
    'clock': {name: 'fa-solid fa-clock', file: faClock},
    'pin': {name: 'fa-solid fa-map-pin', file: faMapPin},
    'artist': {name: 'fa-solid fa-user', file: faUser},
    'arrow-up': {name: 'fa-solid fa-chevron-up', file: faChevronUp},
    'envelope': {name: 'fa-solid fa-envelope', file: faEnvelope},
    'tag': {name: 'fa-solid fa-tag', file: faTag},
    'hamburger': {name: 'fa-solid fa-bars', file: faBars},
    'facebook': {name: 'fa-brands fa-facebook', file: faFacebook},
    'instagram': {name: 'fa-brands fa-instagram', file: faInstagram},
    'linkedin': {name: 'fa-brands fa-linkedin', file: faLinkedin},
    'youtube': {name: 'fa-brands fa-youtube', file: faYoutube},
    'soundcloud': {name: 'fa-brands fa-soundcloud', file: faSoundcloud},
    'spotify': {name: 'fa-brands fa-spotify', file: faSpotify},
    'apple': {name: 'fa-brands fa-apple', file: faApple},
    'deezer': {name: 'fa-brands fa-deezer', file: faDeezer}
};
Object.values(fa).forEach(icon => library.add(icon.file));

const props = defineProps({
    icon: {
        type: String,
        required: true,
        validator(value) {
            return ['info', 'success', 'error', 'doc', 'purchase', 'star', 'clock', 'envelope', 'facebook', 'instagram', 'linkedin', 'youtube', 'soundcloud', 'hamburger', 'pin', 'arrow-up', 'spotify', 'apple', 'tag', 'deezer']
                .includes(value);
        }
    },
    size: {
        type: String,
        default: '1x',
        validator(value) {
            return ['lg', '1x'].includes(value);
        }
    }
});

const faName = computed(() => fa[props.icon].name);
</script>

<template>
    <span class="gr-icon" :class="'gr-icon--' + props.icon">
        <font-awesome-icon :size="size" :icon="faName"/>
    </span>
</template>

<style lang="scss">
@use "../scss/config";

.gr-icon {
    &--facebook {
        color: config.$facebook;
    }

    &--instagram {
        color: config.$instagram;
    }

    &--linkedin {
        color: config.$linkedin;
    }

    &--youtube {
        color: config.$youtube;
    }

    &--soundcloud {
        color: config.$soundcloud;
    }

    &--spotify {
        color: config.$spotify;
    }

    &--deezer {
        color: config.$deezer;
    }
}
</style>
